export default [

  // *===============================================---*
  // *--------- USER ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/users/list',
    name: 'users-list',
    component: () => import('@/views/user/users-list/UsersList.vue'),
    meta: {
      pageTitle: 'Lista de usuarios',
      breadcrumb: [
        {
          text: 'Listar',
          active: true,
        },
      ],
    },
  },
  {
    path: '/users/edit/:id',
    name: 'users-edit',
    component: () => import('@/views/user/users-edit/index.vue'),
    meta: {
      pageTitle: 'Editar usuario',
      breadcrumb: [
        {
          text: 'Usuarios',
          to: '/users/list',
        },
        {
          text: 'Editar',
          active: true,
        },
      ],
    },
  },
  {
    path: '/users/add',
    name: 'users-add',
    component: () => import('@/views/user/users-add/index.vue'),
    meta: {
      pageTitle: 'Agregar usuario',
      breadcrumb: [
        {
          text: 'Usuarios',
          to: '/users/list',
        },
        {
          text: 'Agregar',
          active: true,
        },
      ],
    },
  },
  // *===============================================---*
  // *--------- ENTERPRISE ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/company/list',
    name: 'companys-list',
    component: () => import('@/views/company/companys-list/index.vue'),
    meta: {
      pageTitle: 'Lista de empresas',
      breadcrumb: [
        {
          text: 'Listar',
          active: true,
        },
      ],
    },
  },
  {
    path: '/company/add',
    name: 'companys-add',
    component: () => import('@/views/company/companys-add/index.vue'),
    meta: {
      pageTitle: 'Agregar Empresa',
      breadcrumb: [
        {
          text: 'Empresas',
          to: '/company/list',
        },
        {
          text: 'Agregar',
          active: true,
        },
      ],
    },
  },
  {
    path: '/company/edit/:id',
    name: 'companys-edit',
    component: () => import('@/views/company/companys-edit/index.vue'),
    meta: {
      pageTitle: 'Editar Empresa',
      breadcrumb: [
        {
          text: 'Empresas',
          to: '/company/list',
        },
        {
          text: 'Editar',
          active: true,
        },
      ],
    },
  },

  // *===============================================---*
  // *--------- TEMPLATES ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/template/list',
    name: 'template-list',
    component: () => import('@/views/template/template-list/index.vue'),
    meta: {
      pageTitle: 'Lista de plantillas',
      breadcrumb: [
        {
          text: 'Listar',
          active: true,
        },
      ],
    },
  },
  {
    path: '/template/add',
    name: 'template-add',
    component: () => import('@/views/template/template-add/index.vue'),
    meta: {
      pageTitle: 'Agregar Plantillas',
      breadcrumb: [
        {
          text: 'Plantillas',
          to: '/template/list',
        },
        {
          text: 'Agregar',
          active: true,
        },
      ],
    },
  },
  {
    path: '/template/edit/:id',
    name: 'template-edit',
    component: () => import('@/views/template/template-edit/index.vue'),
    meta: {
      pageTitle: 'Editar Plantilla',
      breadcrumb: [
        {
          text: 'Plantillas',
          to: '/template/list',
        },
        {
          text: 'Editar',
          active: true,
        },
      ],
    },
  },
  // *===============================================---*
  // *--------- CONTRATOS ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/contract/list',
    name: 'contract-list',
    component: () => import('@/views/contract/contract-list/index.vue'),
    meta: {
      pageTitle: 'Lista de Contratos',
      breadcrumb: [
        {
          text: 'Listar',
          active: true,
        },
      ],
    },
  },
  {
    path: '/contract/view/:id',
    name: 'contract-view',
    component: () => import('@/views/contract/contract-view/index.vue'),
    meta: {
      pageTitle: 'Detalle Contrato',
      breadcrumb: [
        {
          text: 'Contratos',
          to: '/contract/list',
        },
        {
          text: 'Ver',
          active: true,
        },
      ],
    },
  },
  {
    path: '/contract/add',
    name: 'contract-add',
    component: () => import('@/views/contract/contract-add/index.vue'),
    meta: {
      pageTitle: 'Agregar Contrato',
      breadcrumb: [
        {
          text: 'Contratos',
          to: '/contract/list',
        },
        {
          text: 'Agregar',
          active: true,
        },
      ],
    },
  },
  {
    path: '/contract/edit/:id',
    name: 'contract-edit',
    component: () => import('@/views/contract/contract-edit/index.vue'),
    meta: {
      pageTitle: 'Editar Contrato',
      breadcrumb: [
        {
          text: 'Contratos',
          to: '/contract/list',
        },
        {
          text: 'Editar',
          active: true,
        },
      ],
    },
  },

  // *===============================================---*
  // *--------- CONTRATOS ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/report/:id',
    name: 'report',
    component: () => import('@/views/reports/index.vue'),
    meta: {
      pageTitle: 'Reporte Contrato',
      breadcrumb: [
        {
          text: 'reporte',
          active: true,
        },
      ],
    },
  },
]
