export default [
  {
    header: 'Modulos',
  },
  {
    title: 'Usuarios',
    icon: 'UserIcon',
    children: [
      {
        id: 'UsuariosListar',
        title: 'Listar',
        route: 'users-list',
      },
      {
        id: 'UsuariosAgregar',
        title: 'Agregar',
        route: { name: 'users-add' },
      },
      {
        id: 'UsuariosEditar',
        title: 'Editar',
        hide: true,
        route: { name: 'users-edit' },
      },
    ],
  },
  {
    title: 'Empresas',
    icon: 'HomeIcon',
    children: [
      {
        id: 'EmpresasListar',
        title: 'Listar',
        route: { name: 'companys-list' },
      },
      {
        id: 'EmpresasAgregar',
        title: 'Agregar',
        route: { name: 'companys-add' },
      },
      {
        id: 'EmpresasEditar',
        title: 'Editar',
        hide: true,
        route: { name: 'companys-edit' },
      },
    ],
  },
  {
    title: 'Plantillas',
    icon: 'FileIcon',
    children: [
      {
        id: 'PlantillasListar',
        title: 'Listar',
        route: { name: 'template-list' },
      },
      {
        id: 'PlantillasAgregar',
        title: 'Agregar',
        route: { name: 'template-add' },
      },
      {
        id: 'PlantillasEditar',
        hide: true,
        title: 'Editar',
        route: { name: 'template-edit' },
      },
    ],
  },
  {
    title: 'Contratos',
    icon: 'FolderIcon',
    children: [
      {
        id: 'ContratosListar',
        title: 'Listar',
        route: { name: 'contract-list' },
      },
      {
        id: 'ContratosAgregar',
        title: 'Agregar',
        route: { name: 'contract-add' },
      },
      {
        id: 'ContratosVer',
        title: 'Ver',
        route: { name: 'contract-view' },
        hide: true,
      },
      {
        id: 'ContratosEditar',
        title: 'Editar',
        hide: true,
        route: { name: 'contract-edit' },
      },
    ],
  },
  {
    id: 'Reportes',
    title: 'Report',
    icon: 'FileIcon',
    route: { name: 'report' },
    hide: true,
  },
  {
    id: 'Perfil',
    title: 'Perfil',
    icon: 'FileIcon',
    route: { name: 'profile' },
    hide: true,
  },

]
