/* eslint-disable */
import useJwt from '@/auth/jwt/useJwt'
import navMenuItems1 from "@/navigation/vertical";
/**
 * Return if user is logged in
 * This is completely up to you and how you want to store the token in your frontend application
 * e.g. If you are using cookies to store the application please update this function
 */
// eslint-disable-next-line arrow-body-style
export const isUserLoggedIn = () => {
  return localStorage.getItem('userData')
}

export const getUserData = () => JSON.parse(localStorage.getItem('userData'))

/**
 * This function is used for demo purpose route navigation
 * In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 * Please note role field is just for showing purpose it's not used by anything in frontend
 * We are checking role just for ease
 * NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = userRole => {
  if (userRole === 'admin') return 'users-list'
  if (userRole === 'regulador') return 'contract-list'
  if (userRole === 'contratista') return 'contract-list'
  return { name: 'auth-login' }
}

export const getUserAcessPermissions = (role) => {
  let permissions = [];
  switch (role) {
    case "admin":
      permissions = [
        "Perfil",
        "UsuariosListar",
        "UsuariosAgregar",
        "UsuariosEditar",
        "EmpresasListar",
        "EmpresasAgregar",
        "EmpresasEditar",
        "PlantillasListar",
        "PlantillasAgregar",
        "PlantillasEditar",
        "ContratosListar",
        "ContratosAgregar",
        "ContratosEditar",
        "ContratosVer",
        "Reportes",
      ];
      break;
    case "contratista":
      permissions = [
        "Perfil",
        "ContratosListar",
        "ContratosVer",
        "Reportes",
      ];
      break;
    case "regulador":
      permissions = [
        "Perfil",
        "ContratosListar",
        "ContratosAgregar",
        "ContratosEditar",
        "ContratosVer",
        "Reportes",
      ];
      break;
  }

  return permissions;
};

export const validateAccess = (route, permissions, role) => {
  let hasAccess = false;
  navMenuItems1.forEach((element) => {
    if (element.route && element.route.name == route.name) {
      if (permissions.indexOf(element.id) != -1) {
        hasAccess = true;
      }
    } else {
      if (element.children) {
        element.children.forEach((child) => {
          if (child.route && child.route.name == route.name) {
            if (permissions.indexOf(child.id) != -1) {
              hasAccess = true;
            }
          }
        });
      }
    }
  });
  return hasAccess;
};