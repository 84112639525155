/* eslint-disable*/
import axios from "axios";
// import jsonData from "@/generalData.json";
import useJwt from "@/auth/jwt/useJwt";
export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    async EXECUTE({ commit }, requestOption) {
      var respdata = await axios(requestOption)
        .then((res) => {
          return res.data;
        })
        .catch((e) => {
          if (e.response) {
            if (e.response.data.error_code == 401 || e.response.data.error_code == 403) {
              localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName);
              localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName);
              localStorage.removeItem("userData");
              window.location.href = "/login";
            }
            return e.response.data;
          } else {
            return e.response;
          }
        });
      console.log("resp", respdata);
      return respdata;
    },
  },
};
