import Vue from 'vue'
import VueRouter from 'vue-router'
/* eslint-disable */
// Routes
import { canNavigate } from '@/libs/acl/routeProtection'
import { isUserLoggedIn,getUserAcessPermissions,validateAccess, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'
import apps from './routes/apps'
import pages from './routes/pages'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    { path: '/' },
    ...apps,
    ...pages,
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn()
  if (!canNavigate(to)) {
    if (!isLoggedIn) return next({ name: 'auth-login' })
    return next({ name: 'misc-not-authorized' })
  }else{
    if(to.fullPath=="/"){
      const userData = getUserData()
      console.log('entro al fullPath')
      const nameRouter = getHomeRouteForLoggedInUser(userData ? userData.role : null)
      next({name:nameRouter})
    }
  }
  const userData = getUserData()
  if (isLoggedIn) {
    if(to.meta.redirectIfLoggedIn){
      next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
    }else{
      let permissions = getUserAcessPermissions(userData.role)
      console.log('permissionsMiddleware', permissions)
      let hasPermission = validateAccess(to, permissions, userData.role)
      const nameRouter = getHomeRouteForLoggedInUser(userData ? userData.role : null)
      console.log('nameRouter', nameRouter,to.name,hasPermission)
      if(!hasPermission && to.name!="auth-login" && to.name!="misc-not-authorized" && to.name!=nameRouter && to.name!=undefined) {
        return next({ name: 'misc-not-authorized' })
      }
    }
  }
  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
